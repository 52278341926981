export default [
  {
    name: 'English',
    detail: 'Fluent (C1)',
    level: 5,
  },
  {
    name: 'French',
    detail: 'Fluent (C1)',
    level: 6,
  },
  {
    name: 'Japanese',
    detail: 'Basic',
    level: 3,
  }
]