export default [
  {
    name: 'Anglais',
    detail: 'Courant (C1)',
    level: 5,
  },
  {
    name: 'Français',
    detail: 'Courant (C1)',
    level: 6,
  },
  {
    name: 'Japonais',
    detail: 'Basique',
    level: 3
  }
]